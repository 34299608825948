/*
 * @Author: 魏鑫
 * @Date: 2019-12-24 11:39:19
 * @LastEditors: 魏鑫
 * @LastEditTime: 2019-12-24 11:39:21
 */
import axios from '@/lib/api.request'
// 新保列表
export const bqList = data => axios.post('/hx/abtComHxAppPos/bqList', data)
// 新保保单详情
export const bqShow = params => axios.get('/hx/abtComHxAppPos/bqShow', { params})
